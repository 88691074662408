<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h3>
        As minhas Competências <strong>{{ currentUser.username }}</strong>
      </h3>
      <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
      <Divider />
      <div
        class="p-field p-col-12 p-md-3"
        v-for="competency in competencyList"
        :key="competency.id"
      >
        <h3
          v-bind:class="[{ 'text-red': !Number.isInteger(competency.value) }]"
        >
          {{ competency.name }}
        </h3>
        <Rating
          v-model="competency.value"
          :disabled="!allowEdition"
          @input="createUpdateUserCompetency(competency)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import competencyService from "../services/competency.service";

export default {
  name: "CompetencyProfileUser",

  data() {
    return {
      loading: true,
      filters: {},
      competencyList: [],
      allowEdition: true,
    };
  },
  computed: {
    currentUser() {
      if (this.$route.params.userId && this.$route.params.username) {
        return {
          id: this.$route.params.userId,
          username: this.$route.params.username,
        };
      } else if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  async created() {
    if (this.$route.params.userId && this.$route.params.username) {
      this.allowEdition = false;
    }
    if (
      this.$store.state.auth.user.isEsa ||
      this.$store.state.auth.user.department == "Administradores"
    ) {
      this.allowEdition = true;
    }
    this.competencyList = await this.getUserCompetencyProfile();
    this.loading = false;
  },
  methods: {
    getUserCompetencyProfile() {
      return competencyService.getUserCompetencyProfile(this.currentUser.id);
    },
    createUpdateUserCompetency(competency) {
      if (competency.value == null) {
        competency.value = 0;
      }

      let params = {
        competencyId: competency.id,
        value: competency.value,
      };
      competencyService
        .createUpdateUserCompetency(this.currentUser.id, params)
        .then((response) => {
          if (!Number.isInteger(response)) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar",
              life: 3000,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.text-red {
  color: red;
}
</style>
